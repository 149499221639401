<template>
    <div class="home">
        <div class="bg-img">
            <div class="banner">
                <div class="container-fluid">
                    <div class="row">
                        <div class="banner-content">
                            <h1 class="do-not-translate">Kiel.RefugeeCompass</h1>
                            <h5>Bildungs-, Beratungs- und Freizeitangebote für Geflüchtete in Kiel</h5>
                        </div>
                    </div>
                    <div class="col-12 col-lg-6 pl-0">
                        <div class="banner-search">
                            <h6>Suche nach passenden Angeboten:</h6>
                            <keyword-search @searchWordChanged="searchWordChanged"/>
                        </div>
                    </div>
                </div>
            </div>

            <main-categories-section />
        </div>

        <event-section role="region"/>

        <div class="section footer-margin">
            <div class="container-fluid">
                <div class="row">

                    <div class="col-12 col-lg-3 col-xl-4">
                        <div class="section-head mt-lg-40">
                            <h3 class="section-head__title">Einrichtungen in deiner Nähe</h3>
                        </div>
                        <router-link :to="{ name: 'Orte'}" v-if="!$isMobileScreen" class="btn btn-hover">Mehr Einrichtungen</router-link>
                    </div>
                    <div class="col-12 col-lg-9 col-xl-8">
                       <single-map-view/>
                       <router-link v-if="$isMobileScreen" :to="{ name: 'Orte'}" title="Mehr Einrichtungen anzeigen" class="btn btn-block mt30 mobile-orte-btn">Mehr Einrichtungen</router-link>
                   </div>
               </div>
           </div>
       </div>
   </div>


</div>
</template>

<script>
import { screenSizeMixin } from '@/mixins/screenSizeMixin';

export default {
    name: "Home",
    mixins: [screenSizeMixin],
    components: {
        SingleMapView: () => import('@/components/map/area.vue'),
        MainCategoriesSection: () => import('./components/mainCategoriesSection.vue'),
        KeywordSearch: () => import('@/components/controls/KeywordSearch'),
        EventSection: () => import('./components/eventSection.vue'),
    },
    methods: {
        searchWordChanged(word){
            this.$router.push({ name: 'Search', query: { keyword: word } })
        }
    }
};
</script>
<style lang="scss">
@import '@/scss/_mixins.scss';
@import '@/scss/_variables.scss';

.mobile-orte-btn {
    max-width: 210px !important;
}

</style>

<style lang="scss" scoped>
@import '@/scss/_mixins.scss';
@import '@/scss/_variables.scss';

.bg-img {
 background-image: url("/assets/RefugeeCompass_bg_rotated_opacity.png");
 background-position: 115% 10%;
 background-size: 900px 900px;
 background-repeat: no-repeat;
 background-color: #fff;

 @media(max-width:1700px) {
     background-position: 130% 0%;
     background-size: 850px 850px;
 }

 @media(max-width:1500px) {
   background-position: 130% -80%;
   background-size: 800px 800px;
}

@media(max-width:1400px) {
   background-position: 125% -30%;
   background-size: 700px 700px;
}

@media(max-width:1250px) {
 background-position: 130% 100%;
 background-size: 600px 600px;
}

@media(max-width:991px) {
    background-position: top -5% right -20%;
    background-size: 400px 400px;
}

@media(max-width:450px) {
    background-position: top -5% right -80%;
    background-size: 350px 350px;
}

.banner-search {
}


}

body[dir="rtl"] {
    .bg-img {
        background-position: top left -10%;

        @media(min-width: 992px) and (max-width:1250px){
            background-position: -10% 100%;
        }


        @media(max-width:991px) {
            background-position: top -5% left 0%;
        }

        @media(max-width:450px) {
            background-position: top -5% left -80%;
        }
    }
}



</style>